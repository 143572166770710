import React, { useState } from "react";
import { Link } from "gatsby";
import { Brand } from "./Brand";
import { Dropdown } from "./Dropdown";

interface Props { }

export const Navigation: React.FC<Props> = ({ }) => {

    const [toggleNavigation, setToggleNavigation] = useState<boolean>(false);

    return (
        <nav className="navigation">
            <Brand />

            <div className="navigation-items mobile-hide">
                <Link className="navigation-item" to="/work">Work</Link>
                <Dropdown
                    dropdownTitle={"Services"}
                    dropdownItems={[
                        { label: "Game Development", link: "/game-development" },
                        { label: "Software Development", link: "/software-development" }
                    ]}
                />
                <Link className="navigation-item" to="/company">Company</Link>
                <Link className="navigation-item" to="/contact">Contact</Link>
            </div>

            <div className="mobile-nav desktop-hide">
                <button aria-label={`${toggleNavigation ? 'Mobile Navigation Open' : 'Mobile Navigation Closed'}`} className={`mobile-nav-button${toggleNavigation ? ' active' : ''}`} onClick={() => setToggleNavigation(!toggleNavigation)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" >
                        <path d="M2,19h20v-2H2V19z M2,13h16v-2H2V13z M2,5v2h20V5H2z" />
                    </svg>
                </button>

                <div className={`navigation-items${toggleNavigation ? ' active' : ''}`}>
                    <Link className="navigation-item" to="/work">Work</Link>
                    <Link className="navigation-item" to="/game-development">Game Development</Link>
                    <Link className="navigation-item" to="/software-development">Software Development</Link>
                    <Link className="navigation-item" to="/company">Company</Link>
                    <Link className="navigation-item" to="/contact">Contact</Link>
                </div>
            </div>
        </nav>
    )
}