import React from "react"
import { Link } from "gatsby"
import { DropdownMenuTop } from "./DropdownMenuTop";

interface Props {
    dropdownTitle: string,
    dropdownTitleLink?: string,
    dropdownItems: DropdownItem[],
}

export type DropdownItem = {
    label: string,
    link: string,
}

export const Dropdown: React.FC<Props> = ({ dropdownTitle, dropdownTitleLink, dropdownItems }) => {
    const createDropdownItems = () => {
        const dropdownElements: JSX.Element[] = [];
        for (let i = 0; i < dropdownItems.length; ++i) {
            const element = <Link
                key={`dropdown-item-${dropdownTitle}-${i}`}
                className="dropdown-link"
                to={dropdownItems[i].link}>
                {dropdownItems[i].label}
            </Link>;
            dropdownElements.push(element);
        }
        return dropdownElements;
    }

    return (
        <div className="dropdown navigation-item">
            {dropdownTitleLink ?
                <Link className="dropdown-title" to={dropdownTitleLink}>{dropdownTitle}</Link>
                :
                <div className="dropdown-title">{dropdownTitle}</div>
            }
            <div className="dropdown-menu">
                <DropdownMenuTop />
                <div className="dropdown-content">
                    {createDropdownItems()}
                </div>
            </div>
        </div>
    )
}