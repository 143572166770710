import React from 'react';
import { Footer } from './Footer';
import { Helm } from './Helm';

interface Props {
    description: string;
    pageTitle?: string;
    footerCoverColour?: string,
    footerClassName?: string,
    url?: string,
    keywords?: string,
}

const Layout: React.FC<Props> = ({ url, keywords, description, children, pageTitle = '', footerCoverColour = "#ffffff", footerClassName = "" }) => {
    return (
        <>
            <Helm pageTitle={pageTitle} description={description} url={url} keywords={keywords} />
            {children}
            <Footer coverColour={footerCoverColour} className={footerClassName} />
        </>
    );
};

export default Layout;
