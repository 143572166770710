

import React from "react";

interface Props {
}

export const DropdownMenuTop: React.FC<Props> = () => {
    return (
        <svg width="200" height="18" viewBox="0 0 200 18">
            <path d="M200,18c0-4.3-3.3-7.8-7.6-8l-82.5-4.6l0,0L107,5.2c0.1,1.9-0.6,3.7-2,5.2c-1.3,1.3-3.1,2.1-4.9,2.1
            c-1.9,0-3.6-0.7-4.9-2.1C93.7,9,93,7.3,93,5.4c0-0.1,0-0.1,0-0.2l-2.9,0.2l0,0L7.6,10C3.3,10.2,0,13.7,0,18L200,18z"/>
            <path d="M103.5,1.9c-2-2-5.1-2-7.1,0c-2,2-2,5.1,0,7.1c2,2,5.1,2,7.1,0C105.5,7,105.5,3.8,103.5,1.9z M102.1,7.5
            c-0.6,0.6-1.3,0.9-2.1,0.9c-0.8,0-1.6-0.3-2.1-0.9c-1.2-1.2-1.2-3.1,0-4.3c0.6-0.6,1.3-0.9,2.1-0.9c0.8,0,1.6,0.3,2.1,0.9
            C103.3,4.5,103.3,6.4,102.1,7.5z"/>
        </svg>
    )
}