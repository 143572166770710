import React from "react";

interface Props {
    fill: string;
    className?: string,
}

export const DiagonalCover: React.FC<Props> = ({ fill, className = "" }) => {
    return (
        <svg width="1920" height="311" viewBox="0 0 1920 311" fill={fill} className={`diagonal-cover ${className}`}>
            <g>
                <path d="M1634,41.5c-3.4,0.4-5.9,2.3-5.7,4.3c0.2,1.9,3.1,3.2,6.5,2.8c3.3-0.5,5.9-2.4,5.7-4.3
                C1640.3,42.4,1637.4,41.1,1634,41.5z"/>
                <path d="M1382.8,87.2c-0.6,0-1.2,0.1-1.8,0.1c-1.9-2.2-4.7-3.6-7.9-3.6c-3.5,0-6.7,1.8-8.6,4.5
                c-72.8,4-146.6,12-206.6,19.8c-5.3-4.5-18.7-6.7-34-4.9c-16.8,1.9-30.3,7.9-33,14.2c-35.5,5.4-59,9.3-63.4,10.2l-0.7,0.1
                c0.1,0,0.4-0.1,0.7-0.1l2.9-0.6c-34.6,5.2,1,2.8,62.2-2.8c5.2,4.7,18.7,6.9,34.2,5.1c14.2-1.6,26-6.2,31-11.3
                c15.5-1.5,31.6-3.1,47.7-4.8c6.6-5.1,21.5-8.7,38.9-8.7c8.3,0,16,0.8,22.5,2.2c36.2-3.9,69.9-7.8,95.7-11c0.5,5.6,5,9.9,10.6,9.9
                c5.8,0,10.6-4.8,10.6-10.8c0-0.6-0.1-1.2-0.2-1.8C1417.6,88.2,1425.5,85.6,1382.8,87.2z"/>
                <path d="M9.7,269.5c25.5-4.6,45.2-13.4,44.1-19.7v-0.1c77.7-12.2,195.2-31.7,174.6-34.2c-3.8-0.5-10.5-0.3-19.2,0.4
		        c-7.7,5.5-18.7,9-30.8,9c-7,0-13.7-1.1-19.5-3.2C101.8,229.5,27.6,242.1,0,246.9V271C3.2,270.6,6.4,270.1,9.7,269.5z"/>
            </g>
            <path d="M1809.6,12.9c-1.2-2.9-10.4-4.4-21.4-3.3c-11.6,1.1-20.7,4.7-20.6,7.8c0.1,0.4,0.2,0.7,0.5,1
            c-67.3,9.6-117.8,18.9-112.8,22.3c1.8,1.2,15.5,0.3,35.7-1.9c9.1-5.7,29.3-9.6,52.7-9.6c6,0,11.8,0.2,17.2,0.7
            c73.7-10.1,159-23.4,159-23.4v68.6l-76.3,7.9c-44.2,7.6-151.7,25.8-237.8,39.5c-1.4,2-6.5,4.1-12.9,5c-5.9,0.9-11.1,0.5-13.3-0.8
            c-54.7,8.5-95.5,14.1-96,12.1c0,0,3.1-4.2,105.9-22.8c31-4.9,62.2-10.4,91.5-15.8c0.9-0.1,1.8-0.2,2.7-0.4l-0.6,0.1
            C1787.8,80.3,1868.3,61,1833.2,66c-48,6.9-104.9,15.9-155.5,24.2c-8.8-5.6-45.4-4.1-88.1,4c-36.8,7-67.5,16.9-79.3,24.9
            c-20.5,8.8-163.8,28.9-302.7,45.9c-8.8,5-23.1,9.4-39.5,11.7c-19.3,2.8-36.5,2-45.8-1.5c-114.8,13.2-206,21.9-189,16.6
            c18.8-5.9,57.2-13.1,93.6-19.2c-13.2,0.3-25.3,0.1-35.9-0.6l-63.4,6.5c-1.3,4-11.9,8.5-25.3,10.4c-14.7,2.1-27,0.4-27.6-3.9
            c0-0.3,0-0.6,0.1-0.9l-40.4,4.2c-1.6,4.5-5.9,7.7-10.9,7.7c-1.1,0-2.1-0.1-3.1-0.4c-5.5,6.3-29.5,13.9-58.9,18.1
            c-34.1,4.8-62.5,3.3-63.5-3.5c-0.4-2.6,3.5-5.7,10.4-8.7l-359.6,56c-0.6,2.1-6.2,4.6-13.3,5.6c-7.7,1.1-14.2,0.1-14.5-2.2
            s5.6-5.1,13.3-6.2c7.4-1,13.7-0.1,14.4,2l74.1-14.4c34.5-6.1,82.2-15.3,127.2-24.4c74-14.9,140.6-29.3,129.1-28.9
            c-28.2,1-99.1,15.2-134.7,22.6c-10-5.1-45.8-3.5-87.5,4.4c-27.6,5.2-51.8,12.1-67.1,18.6c-15.7,6.5-77.4,17.7-147.2,28.9
            c0.1,4-2.7,7.4-6.7,8c-3.4,0.5-6.5-1.2-8-4.1c-12.2,7.5-43.3,15.9-80.4,21.2c-37.5,5.4-70.1,5.9-83.6,2c-37,5.3-62.2,8.8-63.9,9V311
            h1920V0C1884.1,3.7,1845.7,8.2,1809.6,12.9z M16.7,310.8c-4.3,0.6-8-0.7-8.3-3c-0.3-2.3,2.8-4.6,7.1-5.2c4.2-0.6,8,0.8,8.3,3
            C24.1,307.9,21,310.2,16.7,310.8z M1319.1,159.3c-4.3,0.6-8-0.7-8.3-3s2.8-4.6,7.1-5.2c4.3-0.6,8,0.8,8.3,3
            C1326.5,156.4,1323.4,158.7,1319.1,159.3z M1415.4,156.5c-14.7,2.1-27,0.4-27.6-3.9s10.8-9.4,25.4-11.5c14.7-2.1,27-0.3,27.6,3.9
            C1441.4,149.3,1430,154.4,1415.4,156.5z"/>
        </svg>
    )
}